import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { searchString } from '@core/helpers/searchHelpers';
import { httpPost } from '@core/http/requests';
import ConfigListViewDetails from '@core/modules/ControlPanelModule/containers/ConfigsListView/ConfigListViewDetails';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { PageHeader } from '@legacy/components/PageHeader';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

const uuid = v4();

interface Props {
  initializeForm: any;
  alertMessage: (params: { body: string; type: string }) => void;
  onSchemaSelected?: (schemaId: string) => void;
  schemaReducer: ISchemaReducer;
  getRecord: (payload: IGetRecordById, cb: any) => void;
}

interface ITableData {
  key: string;
  appName: string;
  updatedAt: string;
  createdAt: string;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const CONFIG = 'Config';

const ConfigsListViewTable: FC<Props> = (props: Props) => {
  const { alertMessage, schemaReducer, initializeForm, getRecord } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedTableRegions, setSelectedTableRegions] = useState<any[]>([]);
  const [configsList, setConfigsList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isConfigLoading, setIsSchemaActionLoading] = useState<boolean>(false);
  const [selectedEntityName, setSelectedEntityName] = useState<string>('');

  const [configSchema, setConfigSchema] = useState<SchemaEntity | undefined>(undefined);

  let location = useLocation();

  const tableRef = React.createRef<any>();

  useEffect(() => {
    getConfigSchema();
  }, []);

  useEffect(() => {
    getAllConfigsList();
  }, [configSchema]);

  const getConfigSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SCHEMA_MODULE, CONFIG);
      if (schema) {
        setConfigSchema(schema);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setSelectedTableRegions([]);
    setSelectedConfig(undefined);
  }, [currentPage]);

  // Get selected schemaAction details
  useEffect(() => {
    if (selectedTableRegions.length > 0 && tableSlice.length > 0) {
      const config: any = tableSlice[selectedTableRegions[0].rows[0]];
      if (config && !isConfigLoading) {
        getConfigById(config.key);
      }
    }
  }, [selectedTableRegions, tableSlice, selectedEntityName]);

  const getConfigById = async (id: string) => {
    if (configSchema) {
      getRecord({ schema: configSchema, recordId: id }, (response: any) => {
        setSelectedConfig(response);
      });
    }
  };

  const onSelect = (e: any) => {
    setSelectedTableRegions([
      {
        cols: [0, 2],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (configsList.length > 0) {
      let tableData: ITableData[];

      // Get a list of all schemas with entity name and id
      const schemaList = schemaReducer.list.map((s: any) => ({
        id: s.id,
        entityName: s.entityName,
        moduleName: s.moduleName,
        types: s.types || [],
      }));

      tableData = configsList.map((config: DbRecordEntityTransform) => {
        const schema: any = schemaList.find((s: any) => s.id === config.schemaId);
        const typeName: string =
          schema?.types?.find((t: any) => t.id === config.schemaTypeId)?.name || '';

        return {
          key: config.id,
          appName: getProperty(config, 'AppName') || '',
          createdAt: dayjs(config.createdAt).format('MM/DD/YYYY'),
          updatedAt: dayjs(config.updatedAt).format('MM/DD/YYYY'),
        };
      });


      // Apply search
      tableData = tableData.filter((t: ITableData) => {
        return searchString(t.appName, searchKey);
      });

      //  Sort table daya by entityName
      tableData = tableData.sort((a, b) => a.appName.localeCompare(b.appName));

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [configsList, currentPage, searchKey, pageSize, selectedEntityName]);

  const getAllConfigsList = async () => {
    const query = {
      returnQueryPlan: false,
      query: {
        entity: 'SchemaModule:Config',
        type: 'and',
        value: [],
        returnProperties: [
          'id',
          'title',
          'recordNumber',
          'createdAt',
          'schemaId',
          'type',
          'properties.AppName',
          'properties.Mappings',
        ],
        sort: {
          createdAt: {
            order: 'desc',
          },
        },
        pageSize: 1000,
      },
    };
    try {
      await httpPost(`SchemaModule/v2.0/records/search`, query).then((res) => {
        const records = res?.data?.data?.records || [];
        setConfigsList(records);
        console.log('%cdebug: Config Search Results', 'color:limegreen', records);
      });
    } catch (error: any) {
      setConfigsList([]);
      alertMessage({ body: error.response?.data?.message || error.message, type: 'error' });
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedConfig, tableRef, tableSlice]);

  const onSearch = (e: any) => {
    setSelectedTableRegions([]);
    setSearchKey(e.target.value);
  };

  const initializeCreateAction = () => {
    if (configSchema) {
      initializeForm({
        formUUID: uuid,
        isCreateReq: true,
        title: 'Create ' + configSchema.entityName,
        showFormModal: true,
        isBatchCreateReq: false,
        schema: configSchema,
        sections: [{ name: configSchema?.name, schema: configSchema }],
      });
    }
  };

  const onConfigCreate = (params: { event: string; results: any }) => {
    if (params.results && configSchema) {
      getRecord({ schema: configSchema, recordId: params.results.id }, (response: any) => {
        setConfigsList([...configsList, response]);
        setSelectedConfig(response);
      });
    }
  };

  const onConfigUpdate = (config: DbRecordEntityTransform) => {
    if (config) {
      setSelectedConfig(config);
      const index = configsList.findIndex((c) => c.id === config.id);
      if (index > -1) {
        configsList[index] = config;
        setConfigsList([...configsList]);
      }
    }
  };

  const onConfigDelete = (configId: string) => {
    const index = configsList.findIndex((c) => c.id === configId);
    if (index > -1) {
      configsList.splice(index, 1);
      setConfigsList([...configsList]);
    }

    // If selected config is deleted, clear the selection
    if (selectedConfig?.id === configId) {
      setSelectedConfig(undefined);
    }
  };

  const renderListView = () => {
    return (
      <>
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={6}>
              <h2 style={{ margin: 0 }}>Configs</h2>
            </Col>
            <Col span={18} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Configs"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  value={searchKey}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button
                  disabled={!configSchema}
                  icon="plus"
                  intent="success"
                  onClick={initializeCreateAction}
                  text="Create Config"
                />
              </Space>
            </Col>
          </Row>
        </PageHeader>
        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedConfig ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedTableRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[
                getColumnWidthByPercentage(70),
                getColumnWidthByPercentage(15),
                getColumnWidthByPercentage(15),
              ]}
            >
              <Column
                key="appName"
                name="App Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].appName}</Cell>
                )}
              />
              <Column
                key="updatedAt"
                name="Updated At"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].updatedAt}</Cell>
                )}
              />
              <Column
                key="createdAt"
                name="Created At"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].createdAt}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Schema action Details */}
          {selectedConfig && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isConfigLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="Config Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedTableRegions([]);
                      setSelectedConfig(undefined);
                    }}
                  />
                }
              >
                <ConfigListViewDetails
                  schema={configSchema!}
                  config={selectedConfig}
                  onUpdate={onConfigUpdate}
                  onDelete={onConfigDelete}
                />
              </Section>
            </Col>
          )}
        </Row>
        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={configsList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
        <CoreForm
          type="MODAL"
          formUUID={uuid}
          onSubmitEvent={(params: { event: string; results: any }) => onConfigCreate(params)}
        />
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderListView()}</div>;
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getRecord: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(ConfigsListViewTable);
