import { Icon, Menu, MenuItem } from '@blueprintjs/core';
import ConfigsListView from '@core/modules/ControlPanelModule/containers/ConfigsListView';
import FormsListView from '@core/modules/ControlPanelModule/containers/FormsListView';
import ProfileView from '@core/modules/ControlPanelModule/containers/ProfileView';
import SchemaDetailsView from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView';
import SchemaManagerView from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaManagerView';
import { Col, Row } from 'antd';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EmailTemplateManagerView from '../EmailTemplateManager/EmailTemplateManagerView';
import IdentityManagerView from '../IdentityManager/IdentityManagerView';
import './styles.scss';

type Props = RouteComponentProps & {
  moduleName?:
    | 'IdentityManager'
    | 'SchemaManager'
    | 'SchemaManagerDetailView'
    | 'Forms'
    | 'Configs'
    | 'EmailTemplateManager';
  history?: any;
  location?: any;
};

const ControlPanelView: React.FC<Props> = (props: Props) => {
  const { moduleName, history, location } = props;

  const controlPanelRouter = () => {
    if (moduleName === 'IdentityManager') {
      return <IdentityManagerView />;
    } else if (moduleName === 'SchemaManager') {
      return <SchemaManagerView />;
    } else if (moduleName === 'Forms') {
      return <FormsListView />;
    } else if (moduleName === 'Configs') {
      return <ConfigsListView />;
    } else if (moduleName === 'SchemaManagerDetailView') {
      return <SchemaDetailsView />;
    } else if (moduleName === 'EmailTemplateManager') {
      return <EmailTemplateManagerView />;
    } else {
      return <ProfileView />;
    }
  };
  return (
    <Row>
      {/* Navbar */}
      <Col span={3} style={{ padding: 5 }}>
        <Menu style={{ background: 'transparent' }}>
          <MenuItem
            icon={<Icon icon="settings" color="white" />}
            text="Control Panel"
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              background: '#215DB0',
              borderRadius: 5,
              color: '#ffffff',
              marginBottom: 7,
            }}
            active={location.pathname === '/ControlPanelModule/'}
            onClick={() => history.push('/ControlPanelModule/')}
          />

          <MenuItem
            icon="person"
            text="My Settings"
            style={{
              fontWeight: 500,
              cursor: 'pointer',
              borderRadius: 5,
            }}
            onClick={() => history.push('/ControlPanelModule/')}
            active={location.pathname === '/ControlPanelModule/'}
          />

          <MenuItem
            icon="user"
            text="Identity Manager"
            style={{ fontWeight: 500 }}
            onClick={() => history.push('/ControlPanelModule/IdentityManager/')}
            active={location.pathname === '/ControlPanelModule/IdentityManager/'}
          />
          <MenuItem
            icon="one-to-many"
            text="Schema Manager"
            style={{ fontWeight: 500 }}
            onClick={() => history.push('/ControlPanelModule/SchemaManager/')}
            active={location.pathname.indexOf('/SchemaManager/') > -1}
          />

          <MenuItem
            icon="form"
            text="Forms"
            style={{ fontWeight: 500 }}
            onClick={() => history.push('/ControlPanelModule/Forms/ListView')}
            active={location.pathname.indexOf('/Forms/') > -1}
          />

          <MenuItem
            icon="wrench"
            text="Configs"
            style={{ fontWeight: 500 }}
            onClick={() => history.push('/ControlPanelModule/Configs/ListView')}
            active={location.pathname.indexOf('/Configs/') > -1}
          />
          <MenuItem
            icon="search-template"
            text="Email templates"
            style={{ fontWeight: 500 }}
            onClick={() => history.push('/ControlPanelModule/EmailTemplateManager')}
            active={location.pathname.indexOf('/ControlPanelModule/EmailTemplateManager') > -1}
          />
        </Menu>
      </Col>

      {/* Content */}
      <Col
        span={21}
        style={{ height: 'calc(100vh - 40px)', overflowY: 'auto', background: 'white' }}
      >
        {controlPanelRouter()}
      </Col>
    </Row>
  );
};

export default withRouter(ControlPanelView);
