import { Alert, Button, Collapse, Divider, EntityTitle, Section } from '@blueprintjs/core';
import ConfigListJSONDrawer from '@core/modules/ControlPanelModule/containers/ConfigsListView/ConfigJSONDrawer';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { deleteRecordByIdRequest, IDeleteRecordById } from '@legacy/core/records/store/actions';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { connect } from 'react-redux';

interface Props {
  config: DbRecordEntityTransform;
  schema: SchemaEntity;
  onUpdate: (action?: any) => void;
  onDelete: (actionId: string) => void;
  initializeForm: (params: any) => void;
  deleteRecord: (params: IDeleteRecordById, cb: any) => void;
}

const ConfigListViewDetails: FC<Props> = (props: Props) => {
  const { config, onUpdate, onDelete, schema, initializeForm, deleteRecord } = props;

  const [isShowingSchemaActionDetails, setIsShowingSchemaActionDetails] = useState<boolean>(true);
  const [isDeletingConfig, setIsDeletingConfig] = useState<boolean>(false);
  const [editedConfig, setEditedConfig] = useState<DbRecordEntityTransform | undefined>(undefined);

  const [isDeleteConfigAlertVisible, setIsDeleteConfigAlertVisible] = useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);

  const initializeEditConfigForm = () => {
    if (schema && config) {
      initializeForm({
        title: `Edit ${schema?.entityName}`,
        formUUID: config.id,
        selected: config,
        recordType: config?.type,
        showFormModal: true,
        showInitializing: false,
        isCreateReq: false,
        isUpdateReq: true,
        schema: schema,
        sections: [
          {
            name: schema?.name,
            schema: schema,
            associations: [],
          },
        ],
      });
    }
  };

  const deleteConfig = async () => {
    setIsDeletingConfig(true);

    if (schema && config) {
      deleteRecord({ schema: schema, recordId: config?.id }, () => {
        setIsDeletingConfig(false);
        setIsDeleteConfigAlertVisible(false);
        onDelete(config?.id);
      });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  return (
    <>
      <ConfigListJSONDrawer
        schema={schema!}
        config={editedConfig}
        onClose={() => setEditedConfig(undefined)}
        onUpdate={onUpdate}
      />
      <Row style={{ padding: 15 }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5, lineBreak: 'anywhere' }}>
                {config?.title || 'No Record Title'}
              </h3>
              <span>{getProperty(config, 'AppName')}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                icon="trash"
                intent="danger"
                text="Delete"
                fill
                onClick={() => setIsDeleteConfigAlertVisible(true)}
              />
            </Col>
            <Col span={12}>
              <Button icon="annotation" fill text="Edit" onClick={initializeEditConfigForm} />
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Button
                disabled={!!editedConfig}
                fill
                text="Mappings Editor"
                onClick={() => {
                  setEditedConfig(config);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Company ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Config ID"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(config?.id)}
              />
            }
          >
            <span>{config?.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Config Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaActionDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaActionDetails(!isShowingSchemaActionDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaActionDetails}>
                  <Row>
                    {/* Updated At */}
                    <Col span={12} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(config?.updatedAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={12} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(config?.createdAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>
      {/* Delete Config Alert */}
      <Alert
        loading={isDeletingConfig}
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteConfigAlertVisible}
        onCancel={() => setIsDeleteConfigAlertVisible(false)}
        onClose={() => setIsDeleteConfigAlertVisible(false)}
        onConfirm={deleteConfig}
      >
        <p>Are you sure you want to delete this Config? This action cannot be undone.</p>
      </Alert>

      {/* Update Form */}
      <CoreForm
        type="MODAL"
        formUUID={config?.id}
        onSubmitEvent={(params: { event: string; results: DbRecordEntityTransform }) =>
          onUpdate(params.results)
        }
      />
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteRecord: (params: IDeleteRecordById, cb: any) =>
    dispatch(deleteRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(ConfigListViewDetails);
